import { VariantProps, cva } from "class-variance-authority";
import { cn } from "../utils";
import { ArrowRightIcon } from "lucide-react";

export const linkArrowVariants = cva("flex cursor-pointer items-center gap-x-1 text-sm", {
  variants: {
    variant: {
      primary: "text-blue-50 dark:text-black",
      alternate: "text-blue-50 dark:text-white",
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});

interface LinkArrowProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof linkArrowVariants> {
  variant?: "primary" | "alternate";
  children: React.ReactNode;
  onClick?: () => void;
}

export const LinkArrow = ({ children, onClick, variant }: LinkArrowProps) => {
  return (
    <span className={cn(linkArrowVariants({ variant }))} onClick={onClick}>
      <span>{children}</span> <ArrowRightIcon className="size-4" />
    </span>
  );
};
